import { isPlatform } from "@ionic/react";
import { Diagnostic } from "@ionic-native/diagnostic";
import { logError } from "@src/appV2/lib/analytics";
import { ShiftWindow } from "@src/appV2/Shifts/Shift/types";
import { UrgentShift } from "@src/appV2/Shifts/UrgentShifts/api/useUrgentShifts";
import { USER_EVENTS } from "@src/constants";
import { orderBy } from "lodash";
import moment from "moment-timezone";

import { UrgencyType } from "./constants";

export const requestFullLocationAccess = async () => {
  if (!isPlatform("capacitor")) {
    return;
  }
  await requestLocationAccess();
  Diagnostic.switchToSettings();
};

export const requestLocationAccess = async () => {
  const locationStatus = await Diagnostic.getLocationAuthorizationStatus();

  if (locationStatus !== Diagnostic.permissionStatus.NOT_REQUESTED) {
    return;
  }

  await Diagnostic.requestLocationAuthorization();
};

export const requestLocationServiceEnable = () => {
  Diagnostic.switchToLocationSettings();
};

export const getOS = () => (isPlatform("ios") ? "ios" : "android");

export const getLabelsFromPageConfig = (pageConfig: any) => {
  return Object.keys(pageConfig).reduce((acc, key) => {
    const value = pageConfig[key];
    if (typeof value === "string") {
      return { ...acc, [key]: value };
    }

    const type = value?.type;
    const selector = value?.selector;
    const values = value?.values;

    if (type === "variable" && selector && values) {
      switch (selector) {
        case "os":
          return {
            ...acc,
            [key]: values[getOS()],
          };
      }
    }
    return acc;
  }, {});
};

export const handleException =
  (fn: Function) =>
  async (args = []) => {
    try {
      return await fn(...args);
    } catch (error: unknown) {
      logError(USER_EVENTS.URGENT_SHIFTS_UPDATE_LOCATION_ERROR, {
        error,
      });
    }
  };

export const checkIsNotificationSnoozed = (
  isOn: boolean,
  snoozedOn: Date | null,
  snoozedForDays = 1
): boolean => {
  if (snoozedOn && moment().diff(moment(snoozedOn), "days") < snoozedForDays) {
    return true;
  }

  return !isOn;
};

export const checkForUrgentShift = (shift: UrgentShift) => {
  return shift.urgentlyBooked && shift.urgency === UrgencyType.NCNS;
};

export const sortUrgentShifts = (
  shifts: UrgentShift[],
  options: { searchParams: URLSearchParams; isWorkerAteamEnabled?: boolean }
): UrgentShift[] => {
  const { searchParams, isWorkerAteamEnabled = false } = options;
  const shiftStart = searchParams.get("shiftStart");
  // not opened from notification
  if (!shiftStart) {
    return isWorkerAteamEnabled
      ? orderBy(shifts, [(shift) => (shift.window === ShiftWindow.A_TEAM ? 0 : 1)], ["asc"])
      : shifts;
  }

  // opened from notification
  const sortIdentities = [
    (shift: UrgentShift) => shift.start === shiftStart,
    (shift: UrgentShift) => shift.originalAmount ?? 0,
  ];
  const sortOrders: Array<"asc" | "desc"> = ["desc", "desc"];
  if (isWorkerAteamEnabled) {
    sortIdentities.splice(1, 0, (shift: UrgentShift) =>
      shift.window === ShiftWindow.A_TEAM ? 0 : 1
    );
    sortOrders.splice(1, 0, "asc");
  }

  return orderBy(shifts, sortIdentities, sortOrders);
};
